import axios from 'axios'
import Qs from 'qs'
import {Toast} from '@nutui/nutui';
import router from '@/router'


let baseURL = ''
//配置开发环境
if (process.env.NODE_ENV === 'development') {
    baseURL = 'https://apptest.wmdvc.com';
}
 
// 配置生产环境
if (process.env.NODE_ENV === 'production') {
  baseURL = 'https://app.wmdvc.com';
}

const instance = axios.create({
	timeout: 1800000
})

if(localStorage.getItem('token')){
	instance.interceptors.request.use(config => {
		config.headers['token'] = localStorage.getItem('token')
		return config
	})
}

instance.interceptors.request.use((config)=>{
	if(!config.headers['Content-Type']){
	  config.headers['Content-Type'] = 'application/json'
	}
	config.headers['deviceOaid'] = '000'
	config.headers['versionName'] = 'H5'
	config.headers['versionCode'] = '1.0'
	config.headers['loginMethod'] = 'H5'
	return config
})

instance.interceptors.response.use((res)=>{
	if (res.data.errorCode) {
	  let code = res.data.errorCode
	  if (code == '00008' || code == '00009') { // 认证失败
	    localStorage.removeItem('user')
		let url = window.location.href.split('#')[1]
		if(url != '/login'){
			sessionStorage.setItem('redirectPath',url)
		}
	    router.push('/login')
	  }
	  if(code != '00000'){
		  Toast.fail(res.data.errorMsg)
	  }
	}
	return res
})

const headers = {
	'Content-Type':'application/x-www-form-urlencoded',
	'deviceOaid': '000',
	'versionName': 'H5',
	'versionCode': '1.0',
	'loginMethod': 'H5',
	'token': localStorage.getItem('token'),
}
const headers1 = {
	'Content-Type':'application/json',
	'deviceOaid': '000',
	'versionName': 'H5',
	'versionCode': '1.0',
	'loginMethod': 'H5',
	'token': localStorage.getItem('token'),
}
const api = { 
	baseURL,
	headers1,
	getVerificationCode(params) {//获取验证码
		return instance.get(`${baseURL}/auth/getVerificationCode`, {params: params}, { headers})
	},
	login(params) {//登录
		return instance.post(`${baseURL}/login`, params, { headers})
	},
	projectListQueryPage(params) {//获取列表
		return instance.post(`${baseURL}/project/projectListQueryPage`, params)
	},
	getVoteListByUserId(params) {//获取是否跳转投票列表
		return instance.post(`${baseURL}/vote/getVoteListByUserId`, params)
	},
	projectSummary(params) {//获取项目进度
		return instance.post(`${baseURL}/project/projectSummary`, params)
	},
	updateUser(params) {//修改用户
		return instance.post(`${baseURL}/user/updateUser`, params)
	},
	projectDetailsQuery(params) {//获取验证码
		return instance.get(`${baseURL}/project/projectDetailsQuery/${params}`, { headers})
	},
	pageList(curpage,pageSize,params) {//获取动态列表
		return instance.post(`${baseURL}/projectDynamic/pageList?page=${curpage}&size=${pageSize}`, params)
	},
	getInformation(params) {//获取资料
		return instance.get(`${baseURL}/file/getInformation/${params}`, { headers})
	},
	getInformationDetail(params) {//获取资料
		return instance.post(`${baseURL}/file/getInformation`, params)
	},
	getVoteMsgById(params) {//获取投票详情
		return instance.post(`${baseURL}/vote/getVoteMsgById`, params)
	},
	getVoteLastDetailByVoteId(params){//获取投票详情
	console.log(params.projectVoteId)
		return instance.get(`${baseURL}/vote/getVoteLastDetailByVoteId/${params.projectVoteId}`, { headers})
	},
	getVoteLastResultById(params) {//获取投票后详情
		return instance.post(`${baseURL}/vote/getVoteLastResultById`, params)
	},
	getVoteMsgByPjtVtIdAndUserId(params) {//获取投票结果
		return instance.post(`${baseURL}/vote/getVoteMsgByPjtVtIdAndUserId`, params)
	},
	getVoteStatus(params) {//获取当前项目投票状态
		return instance.post(`${baseURL}​/vote/getVoteStatus`, params)
	},
	updateVoteStatus(params) {//修改投票
		return instance.post(`${baseURL}/vote/updateVoteStatus`, params)
	},
	focusOnProjects(params) {//关注项目
		return instance.get(`${baseURL}/project/focusOnProjects/${params.userId}/${params.projectDetailsId}/${params.type}`, { headers})
	},
	selectFocusOnProjects(params) {//查询用户是否关注项目
		return instance.get(`${baseURL}/project/selectFocusOnProjects/${params.userId}/${params.projectDetailsId}`, { headers})
	},
	getAllUser(params) {//获取所有用户
		return instance.get(`${baseURL}/user/getAllUser`, { headers})
	},
	getUserByProjectId(params) {//获取项目参与用户
		return instance.get(`${baseURL}/project/getUserByProjectId/${params.projectId}`, { headers})
	},
	addProjectParticipants(params) {//添加项目参与用户
		return instance.get(`${baseURL}/project/addProjectParticipants/${params.projectId}/${params.userIds}/${params.userId}`, { headers})
	},
	deleteProjectParticipants(params) {//删除项目参与用户
		return instance.get(`${baseURL}/project/deleteProjectParticipants/${params.projectId}/${params.userIds}/${params.userId}`, { headers})
	},
	addProjectComments(params) {//发布评论
		return instance.post(`${baseURL}/project/addProjectComments`, params)
	},
	list(params) {//标签列表
		return instance.get(`${baseURL}/label/list`, { headers})
	},
	getOssStsConfig(params) {//获取oss配置
		return instance.get(`${baseURL}/oss/getOssStsConfig/${params}`, { headers})
	},
	getFilePreviewUrl(params) {//获取预览地址
		return instance.post(`${baseURL}/oss/getFilePreviewUrl`, params)
	},
	setAclPrivateByInfoId(params) {//预览地址失效
		return instance.get(`${baseURL}/oss/setAclPrivateByInfoId/${params}`, { headers})
	},
	getUrlByUploadAddress(params) {//获取预览地址
		return instance.post(`${baseURL}/oss/getUrlByUploadAddress`, params)
	},
	insertInformation(params) {//上传文件
		return instance.post(`${baseURL}/project/insertInformation`, params)
	},
	modifyAvatar(params) {//上传文件
		return instance.post(`${baseURL}/user/modifyAvatar`, params)
	},
	editProjectDetails(params) {//修改项目
		return instance.post(`${baseURL}/project/editProjectDetails`, params)
	},
	addProjectLabel(params) {//修改项目标签
		return instance.post(`${baseURL}/project/addProjectLabel`, params)
	},
	editProjectCapitalizeInfoAndFinanceInfo(params) {//修改财务信息
		return instance.post(`${baseURL}/project/editProjectCapitalizeInfoAndFinanceInfo`, params)
	},
	create(params) {//创建标签
		return instance.post(`${baseURL}/label/create`, params)
	},
	addProject(params) {//新增项目
		return instance.post(`${baseURL}/project/addProject`, params)
	},
	insert(params) {//发起投票
		return instance.post(`${baseURL}/vote/insert`, params)
	},
	selectProjectProgress() {//查询项目进度
		return instance.get(`${baseURL}/dictionary/selectProjectProgress`, { headers})
	},
	selectProjectCategory() {//查询项目分类
		return instance.get(`${baseURL}​/dictionary/selectProjectCategory`, { headers})
	},
	getProtocolByProjectId(params) {//获取协议（0评审中 1已定稿 2作废 3全部）
		return instance.get(`${baseURL}​/protocol/getProtocolByProjectId/${params.projectId}/${params.status}`, { headers})
	},
	getProtocolFileByProjectId(params) {//获取协议（0最新 1历史）
		return instance.get(`${baseURL}​/protocol/getProtocolFileByProjectId/${params.projectId}?type=${params.status}` ,{ headers})
	},
	downOrViewProtocolFile(params) {//查看协议
		return instance.post(`${baseURL}/protocolFile/downOrViewProtocolFile`, params)
	},
	getProtocolRecordByProtocolId(params) {//根据协议id获取协议记录
		return instance.get(`${baseURL}​/protocol/getProtocolRecordByProtocolId/${params.protocolId}` ,{ headers})
	},
	getProtocolRecordByProtocolFileId(params) {//根据协议id获取协议记录
		return instance.get(`${baseURL}​/protocol/getProtocolRecordByProtocolFileId/${params.protocolFileId}` ,{ headers})
	},
	getProtocolFileByProtocolId(params) {//根据协议id获取协议历史记录
		return instance.get(`${baseURL}​/protocol/getProtocolFileByProtocolId/${params.protocolId}` ,{ headers})
	},
	getVoteResultCount(params) {//根据协议文件ID获取已阅/投票计数
		return instance.get(`${baseURL}​/protocol/getVoteResultCount/${params.protocolFileId}` ,{ headers})
	},
	getLastHandlingOpinions(params) {//获取最新的处理意见
		return instance.post(`${baseURL}​/protocol/getLastHandlingOpinions`, params)
	},
	getProtocolByProtocolId(params) {//根据协议id获取详情
		return instance.get(`${baseURL}​/protocol/getProtocolByProtocolId/${params.protocolId}` ,{ headers})
	},
	getVoteResult(params) {//获取参与人/已阅/投票 人员List
		return instance.post(`${baseURL}/protocol/getVoteResult`, params)
	},
	saveProtocolRecord(params) {//新增协议操作记录
		return instance.post(`${baseURL}/protocol/saveProtocolRecord`, params)
	},
	delOrFinProtocol(params) {//作废/定稿协议
		return instance.post(`${baseURL}/protocol/delOrFinProtocol`, params)
	},
	saveOrUpdateProtocolFile(params) {//新增/更新协议文件
		return instance.post(`${baseURL}/protocol/saveOrUpdateProtocolFile`, params)
	},
	saveOrUpdateProtocol(params) {//新增/更新协议
		return instance.post(`${baseURL}/protocol/saveOrUpdateProtocol`, params)
	},
	getParticipantAndAdminByProjectId(params) {//根据项目ID获取项目参与人信息(包含管理员)
		return instance.get(`${baseURL}/project/getParticipantAndAdminByProjectId/${params.projectDetailsId}`)
	},
	selectProjectByProjectName(params) {//根据项目名称获取项目信息
		return instance.get(`${baseURL}/project/selectProjectByProjectName`, {params: params})
	},
	getAppWebLastVersion(params) {//版本控制
		return instance.get(`${baseURL}/version/getAppWebLastVersion`)
	},
	instance: instance

}
export default api