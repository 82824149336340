<template>
  <div class="trends area-inset-top" id="scroll">
	<nut-navbar title="动态" style="margin-bottom: 0;" fixed :safe-area-inset-top="true">
	</nut-navbar>
	<nut-infiniteloading
	 containerId = 'scroll'
	:use-window='false'
	load-icon="loading1"
	pull-icon="loading1"
	load-more-txt="没有啦～"
	:has-more="state.hasMore"
	:is-open-refresh="true"
	 @load-more="loadMore"
	 @refresh="refresh"
	>
		<dt-component :listData="state.listData"></dt-component>
	</nut-infiniteloading>
  </div>
</template>

<script>
	import { reactive, onMounted, getCurrentInstance, ref, toRefs} from 'vue';
	import dtComponent from '@/view/trends/list'
	export default {
		components:{
			dtComponent
		},
		setup() {
			const { proxy } = getCurrentInstance()
			const state = reactive ({
				cur:1,
				listData: [],
				hasMore: true,
				user:[]
			})
			onMounted(()=>{
				state.user = JSON.parse(localStorage.getItem('user'))
				event.getDtList(state.cur,function(){})
			})
			const loadMore = done =>{
				state.cur = state.cur+1
				event.getDtList(state.cur,done)
			}
			const refresh = done =>{
				state.cur = 1
				event.getDtList(state.cur,done)
			}
			const event = {
				getDtList(curpage,fun){
					proxy.$axios.pageList(curpage,10,{projectId:state.projectId,userId:state.user.userId,userRole:state.user.role}).then(res=>{
						if(res.data.errorCode == '00000'){
							let data = res.data.data.records
							let obj = {}
							for(var i = 0;i<data.length;i++){
								let objId = data[i].createTime.split(' ')[0]
								if(obj[objId]){
									obj[objId].push(data[i])
								}else{
									obj[objId] = []
									obj[objId].push(data[i])
								}
							}
							if(curpage!=1){
								Object.keys(obj).forEach(key=>{
									if(state.listData[key]){
										state.listData[key] = state.listData[key].concat(obj[key])
									}else{
										state.listData[key] = obj[key]
									}
								})
							}else{
								state.listData = obj
							}
							if(curpage < res.data.data.pages){
								state.hasMore = true
							}else{
								state.hasMore = false
							}
							fun()
						}
					})
				}
			}
			return {
				state,
				loadMore,
				event,
				refresh,
				 ...toRefs(state)
			};
		}
	}
</script>

<style>
	.trends{
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
	}
</style>